import { Button, Grid } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { MentionableUser, MentionsTextField } from 'src/components/MentionsTextField';
import { theme } from '@local/web-design-system';
import { useIntl } from 'react-intl';
import { useFilterUsersByObjectPermission } from 'src/components';
import {
    EventLinkedObjectType,
    EventsObject,
    PostLinkedObjectType,
    PostsObject,
    subscriptionState,
} from 'state-domains/domain';
import { getPostUsers } from 'src/components/Events/Posts/Posts.utils';
import { useSelector } from 'react-redux';

import { i18n } from './Comments.i18n';
import { useStyles } from './Comments.styles';

export const CommentFormWithMentionableUsers = React.memo(
    (props: {
        event?: EventsObject;
        post?: PostsObject;
        isEvent: boolean;
        commentedId: string;
        setCommentedId: Function;
        onReply: Function;
    }) => {
        const { event, post, isEvent, setCommentedId, onReply, commentedId } = props;
        const {
            selectors: { users: usersSelector },
        } = subscriptionState;

        let projectId;
        let activityId;
        let objectType;
        if (isEvent) {
            if (event?.referenceType === EventLinkedObjectType.PROJECT) {
                projectId = event?.referenceId ?? '';
            } else {
                projectId = event?.project ?? '';
            }
            activityId = event?.activity ?? '';
            objectType = event?.referenceType as EventLinkedObjectType;
        } else {
            projectId = post?.linkedObject?.project ?? '';
            activityId = post?.linkedObject?.activity ?? '';
            objectType = post?.linkedObject?.objectType as PostLinkedObjectType;
        }

        const users = useSelector(usersSelector);
        const { mentionableUsers } = useFilterUsersByObjectPermission({
            projectId,
            activityId,
            objectType,
        });

        const postUsers = useMemo(
            () =>
                isEvent
                    ? { availableMentions: [] }
                    : getPostUsers(post as unknown as PostsObject, users, mentionableUsers),
            [post, users, mentionableUsers],
        );
        return (
            <CommentForm
                id={isEvent ? (event?.id ?? '') : (post?.id ?? '')}
                commentedId={commentedId}
                setCommentedId={setCommentedId}
                onReply={onReply}
                mentionableUsers={isEvent ? mentionableUsers : postUsers.availableMentions}
            />
        );
    },
);

export const CommentForm = ({
    id,
    commentedId,
    setCommentedId,
    onReply,
    mentionableUsers,
}: {
    id: string;
    commentedId: string;
    setCommentedId: Function;
    onReply: Function;
    mentionableUsers: MentionableUser[];
}) => {
    const { classes } = useStyles();
    const [text, setText] = useState('');
    const intl = useIntl();

    const handleAddEventReply = useCallback(() => {
        onReply(text);
        setText('');
    }, [text]);
    const handleCancelReply = useCallback(() => {
        setCommentedId('');
        setText('');
    }, []);

    return (
        commentedId &&
        commentedId === id && (
            <Grid
                automation-id={`reply-textbox-${id}`}
                item
                container
                alignItems="center"
                spacing={1}
                paddingTop="5px"
                flex={1}
            >
                <Grid item xs={12} flex={1} marginBottom={theme.spacing(1)}>
                    <MentionsTextField
                        onChange={(e) => setText(e.target.value)}
                        placeholder={intl.formatMessage(i18n.commentPlaceholder)}
                        value={text}
                        mentionableUsers={mentionableUsers}
                    />
                </Grid>
                <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            className={classes.postButton}
                            onClick={handleCancelReply}
                        >
                            {intl.formatMessage(i18n.cancel)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.postButton}
                            onClick={handleAddEventReply}
                            disabled={!text.trim().length}
                        >
                            {intl.formatMessage(i18n.post)}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    );
};
